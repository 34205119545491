<template>
  <section class="sub_sec">
    <TermsWrap
      :telecom="telecom"
      :termsIdx="termsIdx"
      :termsContents="termsContents"
      @changeTelecom="changeTelecom"
      @changeTermsIdx="changeTermsIdx"
    />
  </section>
</template>

<script>
import TermsWrap from "@/components/Terms/TermsWrap.vue"

export default {
  name: "TermsDetail",
  components: {
    TermsWrap,
  },
  data() {
    return {
      telecom: "",
      termsIdx: "",
      termsContents: "",
    }
  },
  mounted() {
    this.telecom = this.$route.params.telecom
    this.termsIdx = this.$route.params.termsIdx
    this.fetchTerms()
  },
  methods: {
    changeTelecom(idx) {
      this.$router.push({ name: "이용약관상세", path: "terms", params: { telecom: idx, termsIdx: "1" } })
      this.telecom = idx
      this.termsIdx = "1"
      this.fetchTerms()
    },
    changeTermsIdx(termsIdx) {
      this.$router.push({ name: "이용약관상세", path: "terms", params: { telecom: this.telecom, termsIdx } })
      this.termsIdx = termsIdx
      this.fetchTerms()
    },
    async fetchTerms() {
      const httpData = {}
      httpData.Header = {
        CmdType: "010004",
        CallApp: "www",
        RequestID: this.$guid(),
      }
      httpData.Body = {
        TermsType: this.telecom,
        TermsID: this.termsIdx,
      }

      try {
        const { data } = await this.$SRSServer(httpData)
        if (this.termsIdx === "1") this.termsContents = data.Body[0].Terms1.split("\n").join("<br />")
        if (this.termsIdx === "2") this.termsContents = data.Body[0].Terms2.split("\n").join("<br />")
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
