<template>
  <div class="terms_wrap">
    <div class="terms_telecom">
      <button
        v-for="telItem in termsTel"
        :key="telItem.idx"
        @click="handleTelecom(telItem.idx)"
        type="button"
        class="tel_btn"
        :class="telItem.idx === telecom && 'active'"
      >
        {{ telItem.name }}
      </button>
    </div>
    <div class="terms_menu">
      <button
        v-for="menuItem in termsMenu"
        :key="menuItem.idx"
        @click="handleTermsMenu(menuItem.idx)"
        type="button"
        class="menu_btn"
        :class="menuItem.idx === termsIdx && 'active'"
      >
        {{ menuItem.name }}
      </button>
    </div>
    <div class="terms_desc">
      <pre v-html="termsContents" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsWrap",
  props: {
    telecom: {
      type: String,
    },
    termsIdx: {
      type: String,
    },
    termsContents: {
      type: String,
    },
    changeTelecom: {
      type: Function,
    },
    changeTermsIdx: {
      type: Function,
    },
  },
  data() {
    return {
      termsTel: [
        {
          name: "SKT",
          idx: "10",
        },
        {
          name: "KT",
          idx: "20",
        },
        {
          name: "LGU+",
          idx: "30",
        },
      ],
      termsMenu: [
        {
          name: "서비스이용약관",
          idx: "1",
        },
        {
          name: "개인정보취급방침",
          idx: "2",
        },
      ],
    }
  },
  methods: {
    handleTelecom(telIndex) {
      this.$emit("changeTelecom", telIndex)
    },
    handleTermsMenu(menuIndex) {
      this.$emit("changeTermsIdx", menuIndex)
    },
  },
}
</script>
